body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: white;
  background-color: #0D1B13;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

mapbox-search-listbox {
  position: absolute;
}

.mapboxgl-map {
  border-radius: 5px;
}

a {
  color: #00A651;
}

.header {
  background-color: white;
  position: 'fixed';
  z-index: '5';
  width: '100vw';
  padding: '20px';
}

.sidebar {
  background-color: #212121;
  color: #fff;
  padding: 6px 12px;
  font-family: Helvetica, sans-serif;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
  max-width: 250px;
}

h1 {
  font-size: 1.5rem;
  margin: 0px;
}

h2 {
  font-size: 1rem;
}

p {
  font-size: 1rem;
}

.sendDiv {
  position: absolute !important;
  top: 0;
  right: 0 !important;
  z-index: 1;
  border-radius: 4px;
  margin: 0px 10px 10px 0px !important;
  width: fit-content;
}

.leftSendDiv {
  position: absolute !important;
  top: 0;
  left: 0 !important;
  z-index: 1;
  margin: 10px 0px 0px 10px !important;
  border-radius: 4px;
  width: 20%;
}

.innerSendDiv {
  padding: 12px;
  margin-top: 10px !important;
  background-color: white !important;
  border-radius: 4px;
  color: black;
}

.fitContent {
  width: max-content;
}

.innerSendDiv p {
  margin: 0;
}

.sendButton {
  background-color: #0D1B13 !important;
  padding: 6px 12px;
  font-family: Helvetica, sans-serif;
  border-radius: 4px;
}

.footer {
  width: 100vw;
  background-color: white;
  color: black;
  padding: 20px 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.footer div {
  width: 80vw;
  text-align: right;
}
